@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

.background {
  /*background: #09414D!important;*/
  background: rgb(24,76,90)!important;
}

.background2 {
  background: #F8F8F8!important;
}

.color {
  color: #00A66F!important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header a {
  color: #00A66F;
  text-decoration: none;
  font-weight: bolder;
}

header a:hover {
  color: #09414D;
}

#lang-switch label:hover {
  color: #00A66F;
}

.active_language{
  color: #00A66F;
  font-weight: bolder;
}

footer {
  background: black;
  color: white;
}

footer h5{
  color: grey;
}

footer a {
  text-decoration: none;
  color: white;
}

footer a:hover {
  color: grey;
}

.mulish-font {
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-weight: 2;
  font-style: normal;
}


.radio-img > input {
  display: none;
}
.radio-img > .image {
  cursor: pointer;
  border: 2px solid black;
}
.radio-img > input:checked + .image {
  border: 2px solid orange;

}

/*.nav-link > a{*/
/*  color: grey;*/
/*}*/

/*.nav-link > a:hover{*/
/*  color: #00A66F;*/
/*}*/

.nav-link > a:hover{
  color: white;
}

.nav-link > a{
  font-weight: bold;
  color: #00A66F;
}

.fixedButton{
  position: fixed;
  bottom: 50px;
  left: 50px;
  padding: 20px;
}
.roundedFixedBtn{
  height: 75px;
  line-height: 70px;
  width: 75px;
  font-size: 2em;
  font-weight: bold;
  border-radius: 50%;
  background-color: #25d366;
  color: white;
  text-align: center;
  cursor: pointer;
}

.accordion-button:not(.collapsed){
  color: white!important;
  background: rgb(24,76,90)!important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.fixedButton2{
  position: fixed;
  bottom: 50px;
  right: 50px;
  padding: 20px;
}
.roundedFixedBtn2{
  height: 75px;
  line-height: 70px;
  width: 75px;
  font-size: 2em;
  font-weight: bold;
  border-radius: 50%;
  background-color: rgb(24,76,90)!important;
  color: white;
  text-align: center;
  cursor: pointer;
}

.image-cover img{
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.block-intro-info {
  border: 1px red!important;
  height: 200px;
  width: 25vw;
  background: black;
  opacity: 0.7;
}

@media all and (min-width:321px) and (max-width: 480px) {
  .block-intro-info {
    width: 100%;
    height: 300px;
  }
  .splide__track.splide__track--loop.splide__track--ltr.splide__track--draggable{
    padding: 0!important;
  }

  iframe{
    height: 25vh!important;
    width: 100%;
  }

  .scroll-to-top {
    right: 10px!important;
    bottom: 75px!important;
  }
}

.scroll-to-top {
  height: 60px!important;
  width: 60px!important;
  bottom: 15px!important;
}

.modal-content {
  border-radius: 0!important;
}

.info-page *[id]:before {
  display: block;
  content: " ";
  margin-top: -80px;
  height: 80px;
  visibility: hidden;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}